.stories {
  color: white;
  background-image: url(../../assets/img/stories.png);
  background-position: center bottom;
  background-size: cover;
  background-attachment: fixed;
  max-width: 100vw;
}
.stories .content {
  height: inherit;
  backdrop-filter: blur(10px);
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.248);
}
.stories .content .container {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.stories .content .container .section-title h2 {
  color: #fff;
}
.stories .story-text {
  height: 35%;
  backdrop-filter: blur(20px);
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: rgba(0, 0, 0, 0.408);
}
.stories .story-cards {
  height: 65%;
  overflow-x: auto;
}
.stories .story-cards .row {
  height: 100%;
}
.stories .story-cards .story-card {
  height: 65%;
  border-radius: 20px;
  /* border: solid 1px maroon; */
  background-position: center;
  background-size: cover;
  cursor: pointer;
  transition: 0.4s;
}
.stories .story-cards .story-card:hover {
  transform: scale(1.03);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.589);
}
.stories .story-cards .story-card.active {
  height: 85%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.589);
}
.stories .story-cards .story-card.active:hover {
  transform: scale(1);
}
.stories .story-cards .story-card:nth-child(1) {
  background-image: url(https://images.pexels.com/photos/7637400/pexels-photo-7637400.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load);
}
.stories .story-cards .story-card:nth-child(2) {
  background-image: url(https://images.pexels.com/photos/5716492/pexels-photo-5716492.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load);
}
.stories .story-cards .story-card:nth-child(3) {
  background-image: url(https://images.pexels.com/photos/17292030/pexels-photo-17292030/free-photo-of-person-wearing-colorful-shawl-standing-with-cattle.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2);
  background-position: right;
}

@media (max-width: 992px) {
  .stories {
    width: 100vw;
  }
  .stories .content .container {
    height: auto;
  }
  .stories .story-text {
    margin-bottom: 2vh;
    margin-top: 5vh;
    height: auto;
  }
  .stories .story-cards .row {
    height: auto;
    margin-bottom: 5vh;
    overflow-x: scroll;
    flex-wrap: nowrap;
    padding: 20px;
  }
  .stories .story-cards .story-card {
    width: 65vw;
    height: 50vh;
    margin: 10px; /* Add some spacing between cards */
  }
  .stories .story-cards .story-card.active {
    height: 55vh;
    width: 70vw;
  }
}
