.cta .container {
  min-height: 40vh;
  padding-top: 10vh;
  padding-bottom: 10vh;
}
@media (max-width: 992px) {
  .cta {
    width: 100vw;
  }
}
