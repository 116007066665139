.mission {
  background-color: #1a8a21;
  /* background: linear-gradient(white, black); */
  padding-top: 10vh;
  padding-bottom: 10vh;
  color: #f3f3f3;
}
.mission .section-title h2 {
  color: black;
}
.mission .mission-image {
  height: 60vh;
  background-image: url(https://images.pexels.com/photos/18824545/pexels-photo-18824545/free-photo-of-group-of-women-from-an-african-village.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load);
  background-position: center;
  background-size: cover;
  clip-path: polygon(
    0% 0%,
    100% 0%,
    100% 75%,
    75% 75%,
    75% 100%,
    50% 75%,
    0% 75%
  );
}
@media (max-width: 992px) {
  .mission {
    width: 100vw;
  }
  .mission .mission-image {
    margin-top: 5vh;
    width: 90vw;
  }
}
