body {
  margin: 0;
  font-family: "Sedan", serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.section-title {
  margin-bottom: 2vh;
}
.section-title h2 {
  font-size: 52px;
  color: #75392e;
  font-weight: 700;
}
.section-btn {
  width: 180px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #75392e;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: 0.4s;
}
.section-btn:hover {
  border: solid 2px #75392e;
  background-color: transparent;
  color: #75392e;
  transform: scale(1.05);
}
