.about-us {
  height: 100vh;
}
.about-us {
  /* width: 70%; */
}
.about-us .about-image {
  height: 80vh;
  background-image: url(../../assets/img/about.png);
  background-position: center bottom;
  background-size: 80%;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 50%;
}

.about-us .about-image::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  background: radial-gradient(
    transparent,
    rgba(255, 255, 255, 0.722),
    white,
    white
  );
  z-index: 12;
  border-radius: 50%;
}

@media (max-width: 992px) {
  .about-us {
    height: auto;
    margin-top: 10vh;
    padding-bottom: 10vh;
  }
  /* .about-us .about-image {
    height: 50vh;
  } */
}
@media (max-width: 992px) {
  .about-us {
    width: 100vw;
  }
}
@media (max-width: 768px) {
  .about-us .about-image {
    height: 50vh;
  }
}
@media (max-width: 472px) {
  .about-us .about-image {
    height: 30vh;
  }
}
