header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 10vh;
  z-index: 1999;
  background: linear-gradient(rgba(0, 0, 0, 0.892), transparent);
}
header .logo-div img {
  width: 120px;
}

header nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 70vw;
}
header nav .nav-option {
  text-decoration: none;
  font-weight: 600;
  color: #f3f3f3;
}
/* MOBILE NAV */

header .mobile-nav-active {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.673);
  backdrop-filter: blur(10px);
}
header .menu-icon {
  color: #fff;
  font-size: 38px;
  display: none;
}
@media (max-width: 992px) {
  .header {
    width: 100vw;
  }
}
@media (max-width: 768px) {
  header nav {
    display: none;
  }
  header .menu-icon {
    display: block;
  }
}
