.hero {
  height: 110vh;
  background-image: url(https://images.pexels.com/photos/13098709/pexels-photo-13098709.jpeg?auto=compress&cs=tinysrgb&w=800);
  background-position: cover;
  background-attachment: fixed;
  background-size: cover;
  position: relative;
  overflow: hidden;
  clip-path: polygon(
    0 100%,
    4% 97%,
    8% 100%,
    17% 95%,
    18% 100%,
    27% 95%,
    27% 100%,
    36% 96%,
    37% 100%,
    43% 97%,
    49% 100%,
    55% 96%,
    56% 100%,
    63% 97%,
    65% 100%,
    72% 96%,
    72% 100%,
    78% 97%,
    82% 100%,
    88% 96%,
    89% 100%,
    95% 97%,
    100% 100%,
    100% 0,
    0 0
  );
}
.hero::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.892), #b81518b6);
}

.hero .container {
  position: relative;
  z-index: 12;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.hero .container .content {
  width: 60%;
  text-align: center;
}
.hero .container .content h1 {
  font-size: 68px;
  font-weight: 700;
  margin-bottom: 5vh;
}
.hero .container .content p {
  font-size: 18px;
  font-weight: 500;
}
@media (max-width: 992px) {
  .hero {
    width: 100vw;
  }
  .hero .container .content {
    width: 70%;
  }
}
@media (max-width: 768px) {
  .hero {
    min-height: 110vh;
  }
  .hero .container .content {
    width: 90%;
  }
}

@media (max-width: 408px) {
  .hero .container .content {
    width: 98%;
  }
  .hero .container .content h1 {
    font-size: 48px;
  }
  .hero .container .content p {
    font-size: 16px;
  }
}
